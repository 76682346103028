<template>
	<div class="container mt-4 mb-4">
		<div class="loading" v-if="!isContentReady">
            <loader-content :isDisplay="true"></loader-content>
        </div>

		<order-delivery-cover :orderDetail="orderDetail" v-if="isContentReady"></order-delivery-cover>
	</div>
</template>

<script>
import SellerService from '@/services/sellerService';
import OrderDeliveryCover from '@/components/order/DeliveryCover';
import RouterPath from '@/router/path';

export default {
	components: {
		OrderDeliveryCover
	},
	data() {
		return {
			isContentReady: false,
			orderDetail: null
		};
	},
	mounted() {
        this.getContent();
    },
	methods: {
		async getContent() {
            this.isContentReady = false;

			const param = {
				id: this.$route.params.id
			};
            const result = await SellerService.getOrderDetail(param);

			if (result.data) {
				this.orderDetail = result.data.content;
				this.isContentReady = true;
			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}
        }
	}
}
</script>

<style lang="scss" scoped>
.loading {
	height: 500px;
}
</style>