<template>
	<div class="paper">
        <div class="loading" v-if="!isContentReady">
            <loader-content :isDisplay="true"></loader-content>
        </div>

        <template v-if="isContentReady">
            <div class="operation mt-4">
                <button class="btn btn-danger" @click="print()">
                    <b-icon icon="printer" class="icon"></b-icon>
                    PRINT
                </button>
            </div>

            <div class="preview">
                <img id="paperPrint" :src="printImage" v-if="printImage" />
            </div>
			
        </template>

        <div class="print-panel">
            <div ref="printElement">
				<order-delivery-cover-print class="print-cover" :content="orderDetail"></order-delivery-cover-print>
            </div>
        </div>
    </div>
</template>

<script>
import OrderDeliveryCoverPrint from '@/components/order/DeliveryCoverPrint';
import MixinPrintPaper from '@/components/seller/mixins/printPaper';

export default {
	mixins: [ MixinPrintPaper ],
	components: {
		OrderDeliveryCoverPrint
	},
	data() {
		return {
			isContentReady: false,
			deliveryCoverImage: null
		};
	},
    props: {
        orderDetail: Object
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/order/style/PaperPrint.scss';

.operation {
    text-align: center;
    margin-bottom: 30px;

    .btn {
        padding: 7px 15px;
    }
}

.preview {
	display: block;
	margin: auto;
	max-width: 100%;
    width: 800px;
    border: 1px dashed #aaa;
    padding: 0 20px 20px;

    img {
        display: block;
        width: 100%;
    }
}

.print-cover {
    padding-top: 40px;
}
</style>