<template>
    <div class="delivery-print" v-if="content">
        <div class="header">
            <div class="yellow">
                <img src="@/assets/logo-border.png" />
            </div>
            <div class="by">
                <label>จัดส่งโดย</label>
                <span>{{ content.deliveryData.name }}</span>
            </div>
        </div>

        <table class="mt-3">
            <tbody>
                <tr>
                    <td>
                        <label>ผู้ส่ง (FROM)</label>
                        <span>{{ content.storeData.name }}</span>
                        <span>{{ content.storeData.address }}</span>
                    </td>
                    <td class="no-border-right">
                        <label>เลขที่สั่งซื้อ</label>
                        <span>{{ content.orderNumber }}</span>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2">
                        <label>ผู้รับ (TO)</label>
                        <span>{{ content.shippingData.receiverName }}</span>
                        <span>{{ content.shippingData.receiverAddress }}</span>
                        <span>{{ content.shippingData.deliveryRemark }}</span>
                    </td>
                    <td class="no-border-right">
                        <label>โทร</label>
                        <span>{{ content.shippingData.receiverTel }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="no-border-right">
                        <label>PAYMENT</label>
                        <span>ชำระเงินแล้ว</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <table class="mt-3 item-list">
            <thead>
                <tr>
                    <th class="text-center">#</th>
                    <th colspan="2">รายการสินค้า</th>
                    <th class="text-center no-border-right">จำนวน</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in getItemList()" :key="'item' + index">
                    <td class="text-center">{{ item.number }}</td>
                    <td>{{ item.name }}</td>
                    <td v-if="item.size">ขนาดยาง<br />{{ item.size }}</td>
                    <td v-else>-</td>
                    <td class="text-center no-border-right">{{ item.quantity }}</td>
                </tr>
            </tbody>
        </table>

        <div class="remark mt-3">
            <h4>หมายเหตุ:</h4>
            <ul>
                <li>ลูกค้าต้องตรวจเช็คสินค้าเมื่อท่านได้รับสินค้าทันที และปฏิเสธการรับสินค้าหากพบปัญหา เช่น ผิดรุ่น, ผิดสี, จำนวนไม่ครบ หรือชำรุดเสียหาย เป็นต้น</li>
                <li>กรณีที่พบว่าสินค้ามีปัญหา กรุณาถ่ายรูปและคลิปวีดีโอไว้เป็นหลักฐานประกอบนำไปใช้เป็นข้อพิสูจน์เพื่อประกอบการพิจารณา</li>
                <li>ลูกค้าต้องแจ้งเรื่องมาที่ yellowtire.com ในวันที่ได้รับสินค้า หากแจ้งหลังจากวันดังกล่าวถือว่าลูกค้ายอมรับและได้ตรวจรับสินค้าเป็นที่เรียบร้อยแล้ว</li>
                <li>กรณีมีข้อสงสัยใด ๆ โปรดติดต่อเจ้าหน้าที่ Call Center โทร. 0-2026-7407 ภายในวันและเวลาทำการ 07.00 - 16.00 น. หรือ LINE : yellowtire</li>
            </ul>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';

export default {
    data() {
        return {
            helper: Helper
        }
    },
    props: {
        content: Object
    },
    methods: {
        getItemList() {
            const itemList = [];
            let number = 1;

            this.content.products.forEach((item) => {
                itemList.push({
                    number: number,
                    name: item.name + ' (ปี ' + item.year + ')',
                    size: item.size,
                    quantity: item.quantity
                });

                number++;
            });

            this.content.benefits.forEach((item) => {
                if (item.type === Constants.BENEFIT_TYPE.FREE_GIFT) {
                    itemList.push({
                        number: number,
                        name: item.remark,
                        size: '',
                        quantity: ''
                    });

                    number++;
                }
            });

            return itemList;
        },
        splitDeliveryTracking(text) {
           return Helper.splitDeliveryTracking(text);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.delivery-print {
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    width: 21cm;
    padding: 1cm;

    * {
        font-family: 'Tahoma';
        font-size: 12px;
        line-height: 14px;
    }

    .header {
        display: flex;
        align-items: center;

        img {
            display: block;
            width: 250px;
        }

        .by {
            padding: 15px;
            border: 1px solid #999;
            margin-left: 60px;
            flex: 1;
        }
    }

    label {
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
    }

    span {
        display: block;
    }

    table {
        width: 100%;
        border: 1px solid #999;
        border-bottom: none;

        &.item-list {
            th, td {
                vertical-align: middle;
            }
        }

        th, td {
            padding: 7px 10px 9px;
            vertical-align: top;
            border-right: 1px solid #999;
            border-bottom: 1px solid #999;

            &.no-border-right {
                border-right: none;
            }
        }

        thead {
            th {
                color: #555;
                white-space: nowrap;
            }
        }
    }

    .remark {
        ul, li, p {
            font-size: 11px;
        }
    }
}
</style>